<script setup lang="ts">
    import type { Asset } from '~/@types/cms';

    export interface BackgroundVideoProps {
        src?: string;
        data: Asset | undefined;
    }

    const props = defineProps<BackgroundVideoProps>();

    const videoData = computed(() => {
        if (typeof props.data === 'string' || !props.data?.data) return;
        return props.data.data;
    });

    const videoSrc = computed(() => {
        const { baseUrl = '', videoPreview } = unref(videoData) || {};
        if (!videoPreview) return;
        return videoPreview.startsWith('https') ? videoPreview : `${baseUrl}${videoPreview}`;
    });

    const webm = computed(() => {
        return videoData.value?.webm;
    });
</script>

<template>
    <div class="atm-background-video absolute bottom-0 left-0 right-0 top-0 h-full w-full">
        <lazy-atm-image
            v-if="data"
            :data="data"
            class="absolute h-full w-full"
            cover />
        <video
            preload="meta"
            loop
            autoplay
            muted
            playsinline
            class="relative h-full w-full object-cover object-center">
            <source
                :src="videoSrc"
                type="video/mp4" />
            <source
                v-if="webm"
                :src="webm"
                type="video/webm" />
        </video>
    </div>
</template>
